<template>
  <a-modal :visible="showModal" :centered="true" :width="dialogWidth" :destroyOnClose="true" @cancel="handleCancel">
    <template v-slot:title>
      <span style="font-size:13px">{{ title }}</span>
    </template>
    <template v-slot:closeIcon>
      <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;" />
    </template>
    <template v-slot:footer>
      <!-- <a-button-group style="margin-top:10px;width:100%;padding-left:50%;">
        <a-button style="margin-right:30px;" type="primary" @click="closeImageStorage">关闭</a-button>
      </a-button-group> -->
    </template>
    <div class="main">
    <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
      <image-list ref="eleImageList" :image-files="imageFiles" :full-width="dialogWidth" :allow-click-image="false" />
      <a-form-model ref="eleImageStorageForm" :model="oldData" :labelCol="{span:8}" :wrapperCol="{span:16}"
                    style="margin:5px;">
        <a-row style="padding-top: 10px;">
          <a-col :span="8">
            <a-form-model-item label="送样人员：" prop="operator">
              <a-input v-model="fullname" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="催化剂类型：" prop="catalyst_type">
              <a-input v-model="oldData.catalyst_type" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="样品编号：" prop="sample_number">
              <a-input v-model="oldData.sample_number" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="电压：" prop="voltage">
              <a-input v-model="oldData.voltage" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="Spot Size：" prop="spot_size">
              <a-input v-model="oldData.spot_size" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="聚光镜光镧：" prop="condenser_lan">
              <a-input v-model="oldData.condenser_lan" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="物镜光镧：" prop="objective_lense_lan">
              <a-input v-model="oldData.objective_lense_lan" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="AC：" prop="ac">
              <a-input v-model="oldData.ac" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="放大倍数：" prop="magnification">
              <a-input v-model="oldData.magnification" :disabled="isView" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="this.sampleType==1">
          <a-col :span="8">
            <a-form-model-item label="平均长度：" prop="average_length">
              <a-input v-model="oldData.average_length" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均个数：" prop="average_number">
              <a-input v-model="oldData.average_number" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-else-if="this.sampleType==2">
          <a-col :span="8">
            <a-form-model-item label="平均孔隙率：" prop="hole">
              <a-input v-model="oldData.hole" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-else>
          <a-col :span="8">
            <a-form-model-item label="平均长度：" prop="average_length">
              <a-input v-model="oldData.average_length" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均堆叠层数：" prop="average_layer">
              <a-input v-model="oldData.average_layer" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均个数：" prop="average_number">
              <a-input v-model="oldData.average_number" :disabled="true" placeholder="" readonly/>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row style="color:black;margin-top:10px;text-align: right;margin-top: 20px;">
          <a-col :span="24">
            <span style="padding-left:30px">
              图像识别数据
            </span>
            <span>
              <a-button style="margin-left:10px;padding-left: 10px;" icon="upload" type="primary" @click="exportImageStorage">导出</a-button>
            </span>
            <span>
              <a-button style="margin-right:30px;" type="primary" @click="closeImageStorage">关闭</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    </div>
  </a-modal>
</template>

<script>
import {exportImageStorage,getImageStorage} from "../utils/data-manager";
import ImageList from './ImageListMul';


export default {
  name: "ImageStorageDialog",
  components: {
    ImageList
  },
  props: {
    sampleType:{
      type:Number,
      default:0
    },
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "报告详情"
    },
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading:false,
      imageFiles: [],
      dialogWidth: 900,
      oldData: {},
      isView:true
    }
  },
  watch: {
    async showModal(value) {
      if (value) {
        this.loading=true;
        let data=await getImageStorage(this.id);
        this.loading=false;
        this.oldData={
          ...data,
          id:this.id
        };
        this.imageFiles = this.oldData.arrayImageFile;
      } else {
        this.oldData = {};
        this.imageFiles = [];
      }
    }
  },
  computed:{
    fullname(){
      return (this.oldData.department?this.oldData.department+'-':'')+this.oldData.operator;
    }
  },
  methods: {
    closeImageStorage() {
      this.handleCancel();
    },
    handleCancel() {
      this.$parent.closeImageStorage();
    },
    async exportImageStorage() {
      let txtvalue=await exportImageStorage(this.oldData.id);
      if(!txtvalue)
        return;
      const blob = new Blob([txtvalue],{type:"text/plain;charset=utf-8"});
      let date=new Date();
      const fileName = '图像数据导出'+date.getTime()+'.csv';
      const link = document.createElement('a');
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);//,{type:"text/plain;charset='utf-8'"});
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    },
  }
}
</script>

<style scoped>
.main {
  background: white;
}

/deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 3px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-modal-body {
  padding: 0px;
}

/deep/ .ant-modal-body input {
  /* border: none; */
  border-radius: 0px;
  /* border-bottom: 1px solid black; */
}

/deep/ .ant-modal-body input:focus {
  border: none;
  border-radius: 0px;
  /* border-bottom: 1px solid black; */
}

/deep/ .ant-row {
  margin: 0px;
  padding: 0px;
}

.ant-input[disabled] {
  background: white;
  color: black;
}

.ant-input[disabled]:hover {
  cursor: default;
  /* border-bottom: 1px solid black; */
}
.ant-modal-title{
  margin-left: 6px;
}
.ant-modal-footer{
  border-top: none!important;
}
</style>
