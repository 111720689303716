<template>
  <a-modal :visible="previewVisible" :width="900" :footer="null" :title="title" @cancel="closeViewImage" id="view-modal">
  <div class="view-main">
    <div class="view-box">
      <div
        class="movableItem"
        :style="{width:'100%',height:'100%'}"
        @mousewheel="rollImg($event)"
        @mousedown="drag($event,1)"
        ref="bigImage"
      >
        <!-- 图片不可选中 或不可拖拽到新标签打开-->
        <img
          :src="imgUrl"
          oncontextmenu="return false;"
          onselectstart="return false;"
          draggable="false"
          alt
        />
      </div>
    </div>
  </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    imgUrl: {},
    previewVisible:{
      type:Boolean,
      default:false
    },
    title:{
      type:String,
      default:""
    }
  },

  data() {
    return {
      // 图片显示默认大小
      width: "1000px",
      height: "600px",
      // 可缩小倍数,为空则可无限缩小
      minification: 3,
      // 可放大倍数 为空则可无限放大
      magnification: "",
      bigMaxWidth: 1600,
      bigMaxHeight: 800,
    };
  },
  methods: {
    closeViewImage() {
      this.$emit("close-image");
    },
    getImgInfo(
      imgUrl,
      MaxWidth,
      MaxHeight,
      StrWidth,
      StrHeight
    ) {
      var img = new Image();

      img.src = imgUrl;
      let _this = this;
      img.onload = function (e) {
        //  显示时 初始 最大宽度
        let maxWidth = MaxWidth;
        //  显示时 初始 最大高度
        let maxHeight = MaxHeight;
        const naturalWidth = e.currentTarget.naturalWidth;
        const naturalHeight = e.currentTarget.naturalHeight;
        if (
          naturalWidth <= maxWidth &&
          naturalHeight <= maxHeight
        ) {
          _this[StrWidth] = naturalWidth + "px";
          _this[StrHeight] = naturalHeight + "px";
        } else {
          _this[StrWidth] = naturalWidth + "px";
          _this[StrHeight] = naturalHeight + "px";
          if (
            naturalWidth > maxWidth &&
            naturalHeight <= maxHeight
          ) {
            let ratio = naturalWidth / naturalHeight;
            _this[StrWidth] = "1600px";
            _this[StrHeight] = maxWidth / ratio + "px";
          } else if (
            naturalWidth <= maxWidth &&
            naturalHeight > maxHeight
          ) {
            let ratio = naturalWidth / naturalHeight;
            _this[StrWidth] = maxHeight * ratio + "px";
            _this[StrHeight] = "800px";
          } else if (
            naturalWidth > maxWidth &&
            naturalHeight > maxHeight
          ) {
            let ratio = naturalWidth / naturalHeight;
            let w = maxWidth;
            let h = w / ratio;
            if (h > maxHeight) {
              let ratio2 = w / h;
              h = maxHeight;
              w = h * ratio2;
            }
            _this[StrWidth] = w + "px";
            _this[StrHeight] = h + "px";
          }
        }
      }
    },
    // 缩放
    rollImg() {
      var oImg = this.$refs.bigImage;
      const that = this;
      var _this = this;

      function fnWheel(obj, fncc) {
        obj.onmousewheel = fn;
        if (obj.addEventListener) {
          obj.addEventListener("DOMMouseScroll", fn, false);
        }

        function fn(ev) {
          var oEvent = ev || window.event;
          var down = true;
          if (oEvent.detail) {
            down = oEvent.detail > 0;
          } else {
            down = oEvent.wheelDelta < 0;
          }
          if (fncc) {
            fncc.call(this, down, oEvent);
          }
          if (oEvent.preventDefault) {
            oEvent.preventDefault();
          }
          return false;
        }
      }

      fnWheel(oImg, function (down, oEvent) {
        var oldWidth = this.offsetWidth;
        var oldHeight = this.offsetHeight;
        var oldLeft = this.offsetLeft;
        var oldTop = this.offsetTop;
       var parent = oEvent.target.parentNode;
        // 获取父元素距离页面可视区域的位置
        var parentLeft = parent.getBoundingClientRect().left;
        var parentTop = parent.getBoundingClientRect().top;
        // 比例 = （点击位置距离可视窗口位置 - 父元素距离可视窗口位置 - 相对定位的left）/ 本身宽度
        var scaleX = (oEvent.clientX - parentLeft - oldLeft) / oldWidth; //比例
        var scaleY = (oEvent.clientY - parentTop - oldTop) / oldHeight;

        let nowWidth = this.style.width.split("p")[0];
        let initWidth = _this.width.split("p")[0];
        let initHeight = _this.height.split("p")[0];

        let miniFlag = true;
        let magniFlag = true;
        if (_this.minification) {
          // 限制缩小范围
          if (nowWidth <= parseInt(initWidth / _this.minification)) {
            miniFlag = false;
            this.style.width = parseInt(initWidth / _this.minification) + "px";
            this.style.height =
              parseInt(initHeight / _this.minification) + "px";
          }
          if (_this.magnification) {
            // 限制放大范围
            if (nowWidth >= parseInt(initWidth * _this.magnification)) {
              magniFlag = false;
              this.style.width =
                parseInt(initWidth * _this.magnification) + "px";
              this.style.height =
                parseInt(initHeight * _this.magnification) + "px";
            }
          }
        }

        if (down && miniFlag) {
          this.style.width = parseInt(this.offsetWidth * 0.9) + "px";
          this.style.height = parseInt(this.offsetHeight * 0.9) + "px";
        } else if (!down && magniFlag) {
          this.style.width = parseInt(this.offsetWidth * 1.1) + "px";
          this.style.height = parseInt(this.offsetHeight * 1.1) + "px";
        }
        var newWidth = this.offsetWidth;
        var newHeight = this.offsetHeight;

        // 新的相对位置left = 原先的相对位置left - 比例 *（本身新的宽度-旧的宽度）
        this.style.left =
          Math.round(this.offsetLeft - scaleX * (newWidth - oldWidth)) + "px";
        this.style.top =
          Math.round(this.offsetTop - scaleY * (newHeight - oldHeight)) + "px";
      });
    },
    // },
    //拖拽
    drag(ev) {
      var nn6 = document.getElementById && !document.all;
      var isdrag = false;
      var y, x;
      var nTY, nTX;
      var oDragObj;

      function moveMouse(e) {
        if (isdrag) {
          oDragObj.style.top =
            (nn6 ? nTY + e.clientY - y : nTY + event.clientY - y) + "px";
          oDragObj.style.left =
            (nn6 ? nTX + e.clientX - x : nTX + event.clientX - x) + "px";
          return false;
        }
      }

      function initDrag(e) {
        // console.log("点击图片initDrag");
        var oDragHandle = nn6 ? e.target : event.srcElement;
        var topElement = "HTML";
        while (
          oDragHandle.tagName != topElement &&
          oDragHandle.className != "movableItem"
          ) {
          oDragHandle = nn6
            ? oDragHandle.parentNode
            : oDragHandle.parentElement;
        }
        if (oDragHandle.className == "movableItem") {
          isdrag = true;
          oDragObj = oDragHandle;
          // 父元素宽高
          let width = e.path[2].offsetWidth;
          let height = e.path[2].offsetHeight;
          // 这里判断第一次获取不到style 样式 默认为 居中50%
          if (oDragObj.style.top == "") {
            nTY = parseInt((50 * height) / 100 + 0);
            nTX = parseInt((50 * width) / 100 + 0);
          } else {
            nTY = parseInt(oDragObj.style.top + 0);
            nTX = parseInt(oDragObj.style.left + 0);
          }
          y = nn6 ? e.clientY : event.clientY;
          x = nn6 ? e.clientX : event.clientX;
          oDragObj.style.cursor = "move";
          document.onmousemove = moveMouse;
          return false;
        }
      }

      document.onmousemove = initDrag;
      document.onmouseup = function (e) {
        isdrag = false;
        document.onmousemove = null;
        document.onmouseup = null;
        var oDragHandle = nn6 ? e.target : event.srcElement;
        var topElement = "HTML";
        while (
          oDragHandle.tagName != topElement &&
          oDragHandle.className != "movableItem"
          ) {
          oDragHandle = nn6
            ? oDragHandle.parentNode
            : oDragHandle.parentElement;
        }
        if (oDragHandle.className == "movableItem") {
          oDragObj = oDragHandle;
          oDragObj.style.cursor = "hand";
        }
      };
      ev = event || window.event;
    },
  },
  created() {
    this.getImgInfo(
      this.imgUrl,
      this.bigMaxWidth,
      this.bigMaxHeight,
      "width",
      "height"
    );
  },
};
</script>
<style>
.view-main {
  width:100%;
  height:100%;
}

.view-box {
  position: relative;
  overflow: hidden;
  width:100%;
  height:100%;
}

.view-box .movableItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-box .movableItem img {
  z-index: 1;
  width: 100%;
  height: 100%;
}
#view-modal .ant-modal-body{
  padding: 0;
  height:70vh!important;
}
</style>

