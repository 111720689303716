<template>
  <a-dropdown :trigger="['contextmenu']">
    <div v-if="showArrowButton" class="main_hidden">
    <a-icon type="caret-left" class="left" @click="handleLeft"></a-icon>
    <div class="image_container_hidden" :style="{width:(fullWidth-150)+'px'}">
      <div class="image_div" v-for="item in currentList">
        <div style="height: calc(100% - 20px);">
          <img :alt="item.id" :key="item.id" style="width:100%;height:100%;"  :src="item.url" @dblclick="viewImage(item.id)"/>
          <div v-if="allowClickImage" class="mask" :ref="item.id" :style="{opacity: item.opacity}"
               @click="selectImage(item.id)" @dblclick="viewImage(item.id)">
          </div>
        </div>
        <div class="image_name" :title="item.name">{{ item.name }}</div>
      </div>
    </div>
    <a-icon type="caret-right" class="right" @click="handleRight"></a-icon>
    <view-image v-if="currentImage" :preview-visible="previewVisible" :title="currentImage.name" :img-url="currentImage.url"
                @close-image="closeViewImage"/>
      <!--
            <img :alt="currentImage.id" :key="currentImage.id"  :src="currentImage.url" style="width:100%;height:100%;"/>
      -->
  </div>
  <div v-else class="main">
    <div class="image_container" :style="{width:fullWidth+'px'}">
      <div class="image_div" style="display:inline-block;" v-for="item in currentList">
        <div style="height: calc(100% - 20px);">
          <img :alt="item.id" :key="item.id" style="width:100%;height:100%;" :src="item.url" @dblclick="viewImage(item.id)"/>
          <div v-if="allowClickImage" class="mask" :ref="item.id" :style="{opacity: item.opacity}"
               @click="selectImage(item.id)" @dblclick="viewImage(item.id)">
          </div>
        </div>
        <div class="image_name" :title="item.name">{{ item.name }}</div>
      </div>
    </div>
    <view-image v-if="currentImage" :preview-visible="previewVisible" :title="currentImage.name" :img-url="currentImage.url"
                @close-image="closeViewImage"/>
  </div>
  <template #overlay>
    <a-menu @click="handleSelectAll" style="padding:15px">
      <a-menu-item key="1" style="color:green;">全选图片</a-menu-item>
      <a-menu-item key="0">取消全选</a-menu-item>

      <a-menu-item key="2" style="margin-top:10px;color:red;">退出菜单</a-menu-item>
    </a-menu>
  </template>

  </a-dropdown>

</template>

<script>
import ViewImage from "@/components/ViewImage";
export default {
  name: 'ImageList',
  components: {ViewImage},
  props: {
    imageFiles: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Number,
      default: 988
    },
    showArrowButton: {
      type: Boolean,
      default: true
    },
    allowClickImage: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
    },
  },
  data() {
    return {
      currentImage:null,
      previewVisible:false,
      startIndex: 0,
      imageListWidth: 880
    }
  },
  created() {
    this.imageListWidth = this.fullWidth - 65;
  },
  computed: {
    currentList() {
      if(this.showArrowButton){
        let endIndex = this.startIndex + this.imageShowNumber;
        return this.imageFiles.slice(this.startIndex, endIndex);
      }else{
        return this.imageFiles;
      }
    },
    imageShowNumber() {
      if(this.size) {
        return this.size;
      }
      let number = Math.floor((this.fullWidth - 108) / 110);
      return number;
    }
  },
  methods: {
    handleSelectAll(menuitem){
      let key=menuitem.key;
      if(key!="2"){
        this.imageFiles.forEach(ele => {
          if (ele) {
            ele.opacity=key;
          }
        });
      }
    },
    getImageIds() {
      let imageids = [];
      this.imageFiles.forEach(ele => {
        if (ele&&ele.opacity==1) {
          imageids.push(ele.id);
          ele.opacity=0;
        }
      });
      console.log("ids:",imageids);
      return imageids;
    },
    handleLeft() {
      if (this.startIndex > 0) {
        this.startIndex--;
      }
    },
    handleRight() {
      let endIndex = this.startIndex + this.imageShowNumber;
      if (endIndex < this.imageFiles.length) {
        this.startIndex++;
      }
    },
    closeViewImage(){
      this.previewVisible=false;
    },
    viewImage(id) {
      let item = this.imageFiles.find(ele => ele.id==id);
      if(item){
        this.currentImage=item;
        this.previewVisible=true;
      }else{
        this.currentImage=null;
        this.previewVisible=false;
      }
    },
    selectImage(id) {
      let item = this.imageFiles.find(ele => ele.id==id);
      if(item){
        let oldvalue=item.opacity;
        if (oldvalue) {
          oldvalue = 0;
        } else {
          oldvalue = 1;
        }
        item.opacity=oldvalue;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.main_hidden {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 15px 0px rgba(61,74,148,0.6);
  margin: 6px;
  padding: 8px;
}
.image_container_hidden {
  min-height:60px;
  display: flex;
  justify-content: center;
  padding: 2px 0px;
}

.main {
  border: 2px inset;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
  padding: 8px;
}
.image_container {
  min-height:60px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 2px 0px;
  margin: 2px;
}
.left, .right {
  font-size:24px;
  background: white;
  color: lightskyblue;
  border: none;
}
.image_div {
  position: relative;
  margin-right:  10px;
  width: 100px;
  height: 113px;
  text-align: center;
}
.image_div img{
  border: 1px solid gray;
  width: 90px;
  height: 90px;
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0px;
  background: rgb(101, 101, 101, 0.6);
}
.image_name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
}
</style>
