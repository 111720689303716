<template>
  <div>
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">图像数据存储</span>
      </div>
    </div> -->
    <div class="table-search">
      <a-form-model size="small" layout="inline" label-width="68px">
        <a-form-model-item label="催化剂类型：" prop="">
          <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
            <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始时间：" prop="sampleNmberArr">
          <a-date-picker
            v-model="startValue"
            :disabled-date="disabledStartDate"
            show-time
            valueFormat="YYYY-MM-DD HH:mm:ss"
            @openChange="handleStartOpenChange"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间：" prop="">
          <a-date-picker
            v-model="endValue"
            :disabled-date="disabledEndDate"
            show-time
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :open="endOpen"
            @openChange="handleEndOpenChange"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- <div id="queryDiv">
      <span style="padding-left: 20px">催化剂类型：</span>
      <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
        <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
        </a-select-option>
      </a-select>
      <span style="margin: 0 10px">开始时间：</span>
      <a-date-picker
        v-model="startValue"
        :disabled-date="disabledStartDate"
        show-time
        valueFormat="YYYY-MM-DD HH:mm:ss"
        @openChange="handleStartOpenChange"
      />
      <span style="margin: 0 10px">结束时间：</span>
      <a-date-picker
        v-model="endValue"
        :disabled-date="disabledEndDate"
        show-time
        valueFormat="YYYY-MM-DD HH:mm:ss"
        :open="endOpen"
        @openChange="handleEndOpenChange"
      />
      <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>
    </div> -->
  <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
    <!--    图像数据存储的数据表格-->
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
      <a-table rowKey="id" :columns="imageStorageColumns" :dataSource="imageStorageDatas" ref="eleImageStorage" :pagination="paginationOpt" bordered>
        <template v-slot:action="record">
          <a href="javascript:;" v-hasPermi="['imagedata_add']" @click="handleViewImageStorage(record)"><a-icon type="eye" /> 查看 </a>
        </template>
      </a-table>
    </div>
  </a-spin>
    <image-storage-dialog :show-modal="showImageStorage" :id="imageStorageDataId" :sample-type="sampleType" />
  </div>
</template>


<script lang="ts">

import {getCatalystsData, getFilesData, getImageStorageList} from "../utils/data-manager";
import ImageStorageDialog from "./ImageStorageDialog.vue";
import { Modal } from 'ant-design-vue';

export default {
  components: {
    ImageStorageDialog
  },
  data() {
    return {
      sampleType:0,
      loading:false,
      imageStorageColumns:[
        {
          title: '催化剂类型',
          dataIndex: 'catalyst_type',
        },
        {
          title: '报告单号',
          dataIndex: 'report_id',
        },
/*
        {
          title: '催化剂编号',
          dataIndex: 'catalyst_id',
        },
*/
        {
          title: '送检科室',
          dataIndex: 'department',
        },
        {
          title: '图片数量',
          dataIndex: 'image_num',
        },
        {
          title: '报告日期',
          dataIndex: 'generate_date',
        },
        {
          title: '送样人员',
          dataIndex: 'operator',
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          align:'center',
          className: 'operation-cls',
          scopedSlots: {customRender: 'action'},
        }
      ],
      showImageStorage:false,
      imageStorageDataId:null,
      imageStorageDatas:[],
      // 分页
      paginationOpt: {
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 20, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["20", "50", "100"],
        showTotal: (total, range) => `每页${this.paginationOpt.defaultPageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.defaultCurrent = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.search();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.defaultCurrent = current;
          this.paginationOpt.defaultPageSize = size;
          this.search();
        },
      },
      startValue: null,
      endValue: null,
      endOpen: false,
      catalysts: [],
      catalystValue: null,
      files: [],
      fileValue: null
    };
  },
  async created() {
    this.catalysts =await getCatalystsData();
    await this.search();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    handleViewImageStorage(data){
      this.showImageStorage=true;
      this.imageStorageDataId=data.id;
      this.sampleType=data.sampleType;
      if(!this.sampleType){
        this.sampleType=0;
      }
    },
    closeImageStorage(){//子组件调用
      this.showImageStorage=false;
    },
    async search() {
      this.loading=true;
      let res=await getImageStorageList(this.catalystValue, this.startValue, this.endValue, this.fileValue,this.paginationOpt.defaultCurrent,this.paginationOpt.defaultPageSize);
      this.loading=false;
      if(res.flag){
        this.imageStorageDatas =res.data;
        this.paginationOpt.total=res.total;
      }else{
        Modal.error({
          title:"错误信息",
          content:res.msg,
          centered:true,
        });
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    async handleDropdownVisibleChange(open){
      if(open){
        this.files = await getFilesData(this.catalystValue, this.startValue, this.endValue);
      }
    },
    handleChange(value) {
      this.fileValue = value;
    }
  }
}
</script>

<style scoped>
#queryDiv{
  margin: 10px 0
}
#queryDiv span{
  white-space:nowrap;
}
.operation-cls{
  padding:0px;
  width:10px;
}
.operation-cls a{
  margin:0px 2px;
}
/deep/ .ant-input{
  width: 170px!important;
}
</style>
